import React from "react"

import Layout from "components/Layout/LayoutUSTour"
import PageHeader from "components/shared/PageHeader"
import TourDates from "components/us-tour/TourDates";

const AboutPage = () => {
	return(
		<Layout
			title="Tour Dates | North American Tour | Back to the Future the Musical"
			description="Tour Dates | The official website for Back to the Future The Musical - North American Tour. Landing in a city near you from Summer 2024. "
			booking="https://www.telecharge.com/Broadway/Back-to-the-Future-the-Musical/Overview?AID=BWY001390400&utm_source=show_site&utm_campaign=Back-to-the-Future-the-MusicalSS&utm_medium=web"
		>
			<PageHeader city="us-tour" title="Tour Dates" />
            <TourDates />
		</Layout>
	)
}

export default AboutPage
