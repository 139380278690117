import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled, { css } from 'styled-components'
import dayjs from "dayjs";


import { media } from "utils/Media"
import StyledButton from "components/shared/StyledButton";

const TourDatesStyles = styled.section`
  width: 100%;
  padding: 0rem 0 0 0 ;
  .wrapper {
    width: 100%;
    margin: 0 auto;
    h3 {
      text-align: center;
      font-size: 1.5rem;
      @media ${media.md} {
        font-size: 2rem;
      }
      @media ${media.lg} {
        font-size: 2.5rem;
      }
      @media ${media.xl} {
        font-size: 3rem;
      }
    }
    .dates {
      padding: 3rem 0 0 0 ;
      display: grid;
      grid-template-columns: 1fr;
      @media ${media.md} {
        padding-bottom: 3rem;
      }
    }
  }
`
const SingleDateStyles = styled.div`
  width: 100%;
  .wrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: 1.5rem 0;
    h5 {
      font-weight: 700;
      font-size: 1.5rem;
      font-family: ${props => props.theme.font.family.black};
      font-weight: 900;
    }
    p {
      font-weight: 500;
    }
    .button {
      width: 70%;
      margin: 0 auto;
    }
    @media ${media.md} {
      display: grid;
      grid-template-columns: 200px 1fr 1fr 150px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      width: 90%;
      max-width: 1440px;
      margin: 0 auto;
      gap: 3rem;
      h5, p {
        padding: 0;
        margin: 0;
      }
      .city {
        h5 {
          font-size: 1.25rem;
        }
      }
      .theatre {
        text-align: left;
      }
      .date {
        text-align: left;
        font-size: .85rem;
      }
      
      .button {
        flex: 0 0 200px;
        max-width: 200px;
        span {
          font-size: .85rem;
        }
        margin: 0 0 0 auto;
        
      }
    }
    @media ${media.lg} {
      .date {
        text-align: left;
        display: flex;
        gap: 2rem;
      }
      .button {
        flex: 0 0 250px;
        max-width: 250px;
        span {
          font-size: 1.25rem;
        }
      }
    }
    @media ${media.xl} {
      grid-template-columns: 300px 1fr 1fr 300px;
      .city {
        h5 {
          font-size: 1.85rem;
        }
      }
      .date {
        text-align: center;
        display: flex;
        gap: 2rem;
      }
      .button {
        flex: 0 0 250px;
        max-width: 250px;
        span {
          font-size: 1.25rem;
        }
      }
    }
   
  }
    //* odd - even styles
    ${props => props.alt === 'odd' && css`
    background: black;
     h5, p {
      color: #fff;
     }
     @media ${media.md} {
      background: white;
      h5, p {
      color: black;
     }
     }
    `}
  ${props => props.alt === 'even' && css`
  background: white;
  `}
`
const DateButton = styled(StyledButton)`
  width: 100%;
`

export default function TourDates() {
  const debug = false
  const { dates } = useStaticQuery(graphql`
    query {
      dates : allUsTourDatesJson {
        nodes {
          city
          dates
          lastDate
          theatre
          display
          onSale
          url
        }
      }
    }
  `)
  const data = dates.nodes
  return (
    <TourDatesStyles>
        <div className="wrapper">
            {/* <h3>NORTH AMERICAN TOUR LAUNCHING SUMMER 2024!</h3> */}
            <div className="dates">
              {/* {data.map((date, i ) => (
                <SingleDate data={date} key={i} index={i + 1} />
              ))} */}
                {
      data.map((date, i) => {
        //* get todays date
        const today = dayjs();
        //* get last showing date and wrap it in dayjs func
        const lastDate = dayjs(date.lastDate);
        // console.log(lastDate.toDate())
        // console.log(today.toDate())
        //* init isStillRunning var
        let isStillRunning;
        //* if the last day is before todays date set false else set true
        if (lastDate.isBefore(today, "day")) {
          isStillRunning = false;
          {debug && (
            console.log(`event ${date.city} is not running`)
            )}
        } else {
          isStillRunning = true;
        }
        //* conditionally render date
        return isStillRunning ? <SingleDate data={date} key={i}  /> : "";
      })
    }
            </div>
        </div>
    </TourDatesStyles>
  )
}


const SingleDate = ({ data, index }) => {
  const { display, city, theatre, dates, onSale, url } = data
  function oddOrEven(x) {
    return ( x & 1 ) ? "odd" : "even";
  }
  function trueOrFalse(x) {
    return ( x & 1 ) ? true : false;
  }
  return display ? (
    <SingleDateStyles alt={oddOrEven(index)} >
      <div className="wrapper">
        <div className="city">
          <h5>{city}</h5>
        </div>
        <div className="theatre">
          <p>{`${theatre}`}</p>
        </div>
        <div className="date">
          <p>{dates}</p>
        </div>
        <div className="button">
          {onSale ? (
            <DateButton href={url} target="_blank" rel="" white='true'>
                More Info
            </DateButton>
          ) : (
            <DateButton type="button" white='true'>
              <span>{`More Info`}</span>
            </DateButton>
          )}
        </div>
      </div>
    </SingleDateStyles>
  ) : (
    ''
  )
}
